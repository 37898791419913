import * as React from "react";
import Layout from "../components/shared/layout";
import Seo from "../components/shared/seo";
import Footer from "../components/sections/footer";
import Grid from "../components/shared/grid";
import { H4 } from "../components/shared/headings";
import Section from "../components/shared/section";
import DownloadModal from "../components/shared/download-modal";
import Navbar from "../components/navbar";
import { SecondaryPageTitle, SeriousTalk } from "../components/shared/secondary-page-elements";
import { Link } from "gatsby";
import styled from "styled-components";

const NotFoundPage = () => {
  return (
    <Layout>
        <Seo 
            title={"Page not found | Copilot"}
        />
        <DownloadModal/>
        <Navbar/>
        <Section>
            <Grid>
              <SecondaryPageTitle>Page not found</SecondaryPageTitle>
              <SeriousTalk>
                <Subhead>Sorry, we couldn't find what you were looking for.</Subhead>
                <GoHomeButton/>
              </SeriousTalk>
            </Grid>
        </Section>
        <Footer/>
    </Layout>
  )
}

const Subhead = styled(H4)`
    grid-column: col-start / span 7;
    text-align: left;
    max-width: none;
    font-weight: 600;
    ${props => props.theme.device.tablet} {
        margin-bottom: 24px;
    }
`

const GoHomeButton = () => {
  return (
      <ButtonWrapper>
          <StyledLink to="/">Go back home</StyledLink>
      </ButtonWrapper>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 4rem;
  display: block;
`
const StyledLink = styled(Link)`
  text-align: center;
  color: #010912;
  font-weight: 600;
  background-color: ${props => props.theme.color.textLink};
  text-decoration: none;

  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  font-size: ${props => props.theme.fontSize.small};
  letter-spacing: -0.5px;
  padding: 7px 12px 9px;
  border-radius: 6px;
  & svg {
      margin-bottom: -2px;
      margin-right: 6px;
  }

  &:focus {
      outline: none;
  }
  &:focus-visible {
      box-shadow: 
          0 0 0 2pt ${props => props.theme.color.bg},
          0 0 0 5pt ${props => props.theme.color.textSecondary};
  }

  ${props => props.theme.device.tablet} {
      padding: 9px 18px 11px;
  }
  ${props => props.theme.device.tablet} {
      font-size: ${props => props.theme.fontSize.regular2};
  }
`

export default NotFoundPage
